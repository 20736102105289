import { concat, filter } from "lodash";
import React, { InputHTMLAttributes } from "react";

import Wrapper from "./Wrapper";
import { Body } from "../Common/Typography";
import Divider from "../Common/Divider";

import { Fieldset, RadioGroup } from "./__styles__/MultiselectRadio";

// we override some of these props with our own definitions
// for instance, size is normally an integer for <input>
// element but we're using it here as an enum for the style
type StrippedDownInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "onChange"
>;

interface Props extends StrippedDownInputProps {
  name: string;
  type?: string;
  value: string[];
  options: { value: string; label: string }[];
  onChange: (value: string[]) => void;
  label?: Maybe<string>;
  error?: string;
  children?: any;
  description?: Maybe<string>;
  multiselectDescription?: string;
}

const MultiselectRadio = ({
  name,
  value = [],
  options,
  onChange,
  label = null,
  error,
  children,
  required = true,
  description,
  multiselectDescription = "Select all that apply",
}: Props) => (
  <Wrapper
    label={label}
    name={name}
    error={error}
    addon={children}
    required={required}
    description={description}
    labelTabIndex={-1}
    printValue={value.join(", ")}
  >
    <Fieldset>
      <Body size="default" type="regular" color="contentSecondary">
        {multiselectDescription}
      </Body>
      <RadioGroup>
        {options.map(option => (
          <div key={option.value}>
            <label>
              <input
                type="checkbox"
                name="value[]"
                value={option.value}
                checked={value.includes(option.value)}
                onChange={evt => {
                  let newSubject;
                  if (evt.target.checked) {
                    newSubject = concat(value, [option.value]);
                  } else {
                    newSubject = filter(value, s => s !== option.value);
                  }
                  onChange(newSubject);
                }}
              />
              <span>{option.label}</span>
            </label>
            <Divider />
          </div>
        ))}
      </RadioGroup>
    </Fieldset>
  </Wrapper>
);

export default MultiselectRadio;
