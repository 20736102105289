import React, { ComponentProps, Fragment, ReactNode } from "react";
import {
  Controller,
  FieldValues,
  UseControllerProps,
  UseFormRegister,
} from "react-hook-form";
import Wrapper from "./Wrapper";
import { Input, InputWrapper } from "./__styles__/TextInput";
import TextInput, { SymbolComponent, Props as TextProps } from "./Text";
import { isEmpty } from "lodash";

type NumberProps = {
  name: string;
  value: number | null;
  onChange: (value: number | null) => void;
  children?: ReactNode;
} & Omit<ComponentProps<typeof TextInput>, "onChange">;

export default ({ onChange, children, ...props }: NumberProps) => {
  return (
    <TextInput
      type="number"
      onChange={value => onChange(value ? parseFloat(value) : null)}
      {...props}
    >
      {children}
    </TextInput>
  );
};

// For use with react-hook-form forms
export const ReactHookFormNumberInput = ({
  name,
  label = null,
  value,
  error,
  children,
  required,
  register,
  size = "medium",
  placeholder,
  step = 0,
  description,
  expandWithError = true,
  rules = {},
  prefix,
  ...props
}: Omit<TextProps, "postfix"> & {
  expandWithError?: boolean;
  step?: number | string;
  register: UseFormRegister<any>;
  rules?: Parameters<UseFormRegister<any>>[1];
}) => {
  rules = Object.assign(rules, {
    valueAsNumber: true,
  });

  const prefixed = !!prefix;

  const InputComponentWrapper = prefixed ? InputWrapper : Fragment;

  return (
    <Wrapper
      label={label}
      name={name}
      error={error}
      addon={children}
      required={required}
      description={description}
      expandWithError={expandWithError}
      printValue={value?.toString() ?? ""}
    >
      <InputComponentWrapper>
        <SymbolComponent value={prefix} />
        <Input
          step={step}
          type="number"
          id={name}
          size={size}
          placeholder={placeholder}
          error={!!error}
          prefixed={prefixed}
          {...props}
          {...register(name, rules)}
        />
      </InputComponentWrapper>
    </Wrapper>
  );
};

type DoublePrecisionInputProps<TFieldValues extends FieldValues> = Omit<
  UseControllerProps<TFieldValues>,
  "control"
> & {
  control: UseControllerProps<TFieldValues>["control"];
} & ComponentProps<typeof TextInput>;

export const DoublePrecisionNumberInput = <Form extends FieldValues>({
  control,
  name,
  onClick,
  error,
  disabled,
  label,
  required,
}: DoublePrecisionInputProps<Form>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <TextInput
          name={name}
          label={label}
          type="number"
          step={0.01}
          size="small"
          error={error}
          onChange={(value, ...args) => {
            return field.onChange(isEmpty(value) ? null : value, ...args);
          }}
          value={field.value}
          required={required}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    />
  );
};
