import React from "react";
import { InformationalTooltip } from "../Common/Tooltip";

import { LabelMeta, Required } from "./__styles__/Label";
import { Label, lineHeights, TextSize } from "../Common/__styles__/Typography";
import { Body, BodyType } from "../Common/Typography";
import { FlexColumn, InlineBlock } from "../Common/__styles__/Layout";
import { spacing } from "../../stitches.config";
import { ScreenOnly } from "../Common/__styles__/ScreenOnly";

export type LabelProps = {
  description?: Maybe<string>;
  disabled?: boolean;
  htmlFor?: string;
  required?: boolean;
  size?: TextSize;
  style?: React.CSSProperties;
  tabIndex?: number;
  text: string;
  tooltip?: string;
  type?: BodyType;
  compact?: boolean;
} & Parameters<typeof Label>["0"];

export default ({
  description,
  disabled = false,
  required = false,
  size = "default",
  tabIndex = 0,
  text,
  tooltip,
  type = "emphasis",
  compact = false,
  htmlFor,
  ...props
}: LabelProps) => {
  const hasDescription = !!description;
  const hasRequired = !!required;
  const hasTooltip = !!tooltip;
  const hasLabelMeta = hasRequired || hasTooltip;

  return (
    <Label
      tabIndex={tabIndex}
      size={size}
      type={type}
      compact={compact}
      htmlFor={htmlFor}
      {...props}
    >
      <FlexColumn style={{ gap: spacing.xs.value }}>
        <InlineBlock style={{ lineHeight: lineHeights.s }}>
          {text.trim()}
          {hasLabelMeta && (
            <LabelMeta>
              {hasRequired && <Required>*</Required>}
              {hasTooltip && <LabelTooltip text={tooltip} />}
            </LabelMeta>
          )}
        </InlineBlock>
        {hasDescription && (
          <ScreenOnly>
            <Body
              size={"small"}
              type="regular"
              color={disabled ? "contentDisabled" : "contentSecondary"}
            >
              {description}
            </Body>
          </ScreenOnly>
        )}
      </FlexColumn>
    </Label>
  );
};

const LabelTooltip = ({ text }: { text: string }) => {
  return <InformationalTooltip tooltipText={text} place="right" />;
};
