import React, { ReactNode } from "react";
import {
  Fieldset,
  HelperTextWrapper,
  HelperTextSpan,
} from "./__styles__/Wrapper";

import Label, { LabelProps } from "./Label";
import { PrintOnly } from "../Common/__styles__/PrintOnly";
import { Body } from "../Common/Typography";
import { ScreenOnly } from "../Common/__styles__/ScreenOnly";
import { isNil } from "lodash";

export type WrapperProps = {
  name: string;
  children: ReactNode;
  label?: undefined | Maybe<string>;
  error?: Maybe<string>;
  addon?: ReactNode;
  expandWithError?: boolean;
  tooltip?: string;
  compactLabel?: LabelProps["compact"];
  labelTabIndex?: LabelProps["tabIndex"];
  labelSize?: LabelProps["size"];
  helperText?: Maybe<string>;
  printValue?: string;
} & Pick<LabelProps, "description" | "disabled" | "required">;

const HelperText = ({
  errorText,
  defaultText,
}: {
  errorText?: Maybe<string>;
  expandWithError?: boolean;
  defaultText?: Maybe<string>;
}) => {
  if (!errorText && !defaultText) {
    return null;
  }

  return (
    <HelperTextWrapper>
      <HelperTextSpan isError={!!errorText}>
        {errorText ?? defaultText}
      </HelperTextSpan>
    </HelperTextWrapper>
  );
};

export default ({
  label,
  compactLabel = false,
  name,
  error,
  addon,
  labelTabIndex,
  children,
  required,
  description,
  disabled,
  expandWithError = true,
  tooltip,
  labelSize,
  helperText,
  printValue,
}: WrapperProps) => {
  return (
    <Fieldset error={!!error}>
      {!!label && (
        <Label
          text={label}
          compact={compactLabel}
          htmlFor={name}
          tabIndex={labelTabIndex}
          required={required}
          description={description}
          disabled={disabled}
          size={labelSize}
          tooltip={tooltip}
        />
      )}
      {addon}
      {!isNil(printValue) ? <ScreenOnly>{children}</ScreenOnly> : children}
      {!isNil(printValue) && (
        <PrintOnly width="full">
          <Body size={"default"} type={"regular"} testId={`print-only-${name}`}>
            {printValue.trim() || "--"}
          </Body>
        </PrintOnly>
      )}
      <HelperText
        errorText={error}
        expandWithError={expandWithError}
        defaultText={helperText}
      />
    </Fieldset>
  );
};
