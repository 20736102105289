import * as RadioGroup from "@radix-ui/react-radio-group";
import { isEmpty, isNil } from "lodash";
import React, { forwardRef, JSXElementConstructor } from "react";
import { Icon, IconProps } from "../Common/Icons/LucideIcons";
import type Stitches from "@stitches/react";
import { Body } from "../Common/Typography";

import {
  StyledSelectionCardGroup,
  SelectionCard,
  SelectionCardTextBlock,
  IconWrapper,
} from "./__styles__/SelectionCardGroup";

export interface SelectionCardOption {
  /**
   * An ID value is required for the React `key` prop. Consider using
   * an actual unique identifier or a combination of the name of the
   * field and its value
   */
  id: string;
  value: string;
  title: string;
  description?: string;
  iconName?: IconProps["iconName"];
  ghost?: boolean;
}

export interface SelectionCardGroupProps
  extends Stitches.VariantProps<typeof StyledSelectionCardGroup>,
    Omit<RadioGroup.RadioGroupProps, "onChange"> {
  options: Array<SelectionCardOption>;
  onChange: (value: string) => void;
  cardTitleProps?: Partial<React.ComponentProps<typeof Body>>;
}

// Utility-type for combining props from Radix component props
// and styled component props
type RadixRefStyledComponentProps<
  RadixType,
  StyledComponent extends
    | keyof JSX.IntrinsicElements
    | JSXElementConstructor<any>
> = Omit<RadixType, "asChild"> & React.ComponentProps<StyledComponent>;

type ForwardRefSelectionCardProps = RadixRefStyledComponentProps<
  RadioGroup.RadioGroupItemProps,
  typeof SelectionCard
> &
  SelectionCardOption & {
    cardTitleProps?: Partial<React.ComponentProps<typeof Body>>;
  };
const ForwardRefSelectionCard = forwardRef<
  HTMLButtonElement,
  ForwardRefSelectionCardProps
>((props, forwardedRef) => {
  const {
    id,
    value,
    title,
    description,
    iconName,
    cardTitleProps,
    ...refProps
  } = props;

  return (
    <SelectionCard {...refProps} ref={forwardedRef}>
      {!isNil(iconName) && !isEmpty(iconName) && (
        <IconWrapper>
          <Icon iconName={iconName} color={"contentSecondary"} size={16} />
        </IconWrapper>
      )}
      <SelectionCardTextBlock>
        <Body
          size="default"
          type="emphasis"
          style={{ marginBottom: 0 }}
          {...cardTitleProps}
        >
          {title}
        </Body>
        {!isNil(description) && !isEmpty(description) && (
          <Body
            style={{ marginBottom: 0 }}
            color="contentSecondary"
            size="small"
            type="regular"
          >
            {description}
          </Body>
        )}
      </SelectionCardTextBlock>
    </SelectionCard>
  );
});

type ForwardRefSelectionCardGroupProps = RadixRefStyledComponentProps<
  RadioGroup.RadioGroupProps,
  typeof StyledSelectionCardGroup
> & {
  // property name pulled from console logging props passed to component
  "data-orientation"?: "vertical" | "horizontal";
};

const ForwardRefSelectionCardGroup = forwardRef<
  HTMLDivElement,
  ForwardRefSelectionCardGroupProps
>((props, forwardedRef) => {
  const direction =
    props["data-orientation"] === "vertical" ? "column" : undefined;

  return (
    <StyledSelectionCardGroup
      {...props}
      direction={direction}
      ref={forwardedRef}
    />
  );
});

const SelectionCardGroup = ({
  onChange,
  options,
  borderStyle,
  spacingStyle,
  cardTitleProps,
  ...radioGroupProps
}: SelectionCardGroupProps) => {
  return (
    <RadioGroup.Root onValueChange={onChange} {...radioGroupProps} asChild>
      <ForwardRefSelectionCardGroup
        borderStyle={borderStyle}
        spacingStyle={spacingStyle}
      >
        {options.map(({ id, value, ...optionProps }) => (
          <RadioGroup.Item key={id} id={id} value={value} asChild>
            <ForwardRefSelectionCard
              id={id}
              value={value}
              cardTitleProps={cardTitleProps}
              {...optionProps}
            />
          </RadioGroup.Item>
        ))}
      </ForwardRefSelectionCardGroup>
    </RadioGroup.Root>
  );
};

SelectionCardGroup.displayName = "SelectionCardGroup";

export default SelectionCardGroup;
