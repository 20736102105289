import { uniq } from "lodash";
import React, { KeyboardEventHandler, useState } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import Wrapper from "./Wrapper";

// This is to hide the dropdown icon from the input
const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

type ReactHookFormTagsProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: string;
};

export function Tags<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  error,
  name,
  rules,

  ...reactTagsProps
}: ReactHookFormTagsProps<TFieldValues, TName>) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = ({
    value,
    onChange,
  }: {
    value: string[];
    onChange: (tags: string[]) => void;
  }) => {
    return (event: Parameters<KeyboardEventHandler>[0]) => {
      if (!inputValue) return;
      switch (event.key) {
        case "Enter":
        case "Tab":
        case ",":
          onChange(uniq([...(value || []), inputValue]));
          setInputValue("");
          event.preventDefault();
      }
    };
  };

  const handleOnChange = ({
    onChange,
  }: {
    onChange: (tags: string[]) => void;
  }) => {
    // The typing from the select library requires this to be any
    return (newValue: MultiValue<any>) => {
      onChange(newValue.map(option => option.value));
    };
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Wrapper name={name} error={error} printValue={inputValue}>
          <CreatableSelect
            className={"tags-input"}
            classNamePrefix={"tags-input"}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleOnChange(field)}
            onInputChange={newValue => setInputValue(newValue)}
            onKeyDown={handleKeyDown(field)}
            placeholder="Enter value(s)"
            value={field.value?.map(createOption)}
            {...reactTagsProps}
          />
        </Wrapper>
      )}
    />
  );
}
